import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar/Navbar";
import axios from 'axios';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./pages/";
import Login from "./pages/login";
import Search from "./pages/search";
import Taxpayer from "./pages/taxpayer";
import Logout from "./pages/logout";
import TwoFactor from "./pages/twoFactor";
import './App.css';

 
function App() {
    const getInitialUserState = () => {
        axios.get('https://tcotinc.com:3005/checkSession', {withCredentials:true} )
        .then(response => {
            setAuthenticatedUser(response.data.username)
        })
        .catch(error => {
            setAuthenticatedUser("")
            console.log(error)
        });
    };

    const [authenticatedUser, setAuthenticatedUser] = useState(getInitialUserState);

    /*
    useEffect(() => {
        axios.get('https://tcotinc.com:3005/checkSession', {withCredentials:true} )
        .then(response => {
            setAuthenticatedUser(response.data.username)
        })
        .catch(error => {
            setAuthenticatedUser("")
            console.log(error)
        });
    });*/

    return (
        <Router>
            <Navbar authenticatedUser={authenticatedUser} />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/search" element={<Search authenticatedUser={authenticatedUser} />} />
                <Route path="/taxpayer" element={<Taxpayer authenticatedUser={authenticatedUser} />} />
                <Route path="/twoFactor" element={<TwoFactor authenticatedUser={authenticatedUser} />} />
                <Route path="/login" element={<Login authenticatedUser={authenticatedUser} setAuthenticatedUser={setAuthenticatedUser} />} />
                <Route path="/logout" element={<Logout authenticatedUser={authenticatedUser} setAuthenticatedUser={setAuthenticatedUser} />} />
            </Routes>
        </Router>
    );
}
 
export default App;