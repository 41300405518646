import React from 'react';
import { Nav, NavLink, NavMenu } from "./NavbarElements";
import AuthenticateNav from './AuthenticateNav';
import logo from "../New TCOT Logo v2.0 - black.png"

/*old home link in nav bar between logo and authenticated nav

                    <NavLink to="/" activestyle="true">
                        Home
                    </NavLink>*/

const Navbar = (authenticatedUser) => {
     return (
        <>
            <Nav>
                <NavMenu>
                    <NavLink to="/" activestyle="true">
                        <img className="logo" src={logo} width={163.77} height={85} alt=""></img>
                    </NavLink>
                    <AuthenticateNav authenticatedUser={authenticatedUser}/>
                </NavMenu>
            </Nav>
        </>
    );
}
 
export default Navbar;