import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import './login.css';
import logo from "../components/New TCOT Logo v2.0 - black.png"

function Authenticate( {authenticatedUser, setAuthenticatedUser} ) {
  const [resp, setResp] = useState('');
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');
  const [otp, setOTP] = useState('');
  const [twoFAEnabled, setTwoFA] = useState(false);
  const navigate = useNavigate();

  if(authenticatedUser !== null && authenticatedUser !== ""){
    navigate("/search");
  }

  const checkCreds = () => {
    if (!user && !pass) return;
    axios.post('https://tcotinc.com:3005/login/', {username:user, password:pass}, {withCredentials:true} )
    .then(response => {
      console.log(response.data.message);
      setResp(response.data.message);
      setTwoFA(response.data.twoFAEnabled);
      if(!response.data.twoFAEnabled){
        setAuthenticatedUser(user);
        navigate("/search");
      }
    })
    .catch(error => {
      //console.log(error)
      if (error.response.status === 401){
        setResp(error.response.data.error);
      }
      else 
        setResp('Server Error Fetching Response:', error);
    });
  };

  const checkOTP = () => {
    if (!user && !pass) return;
    //console.log("OTP: " + otp)
    axios.post('https://tcotinc.com:3005/checkOTP/', {data: {otp:otp} }, {withCredentials:true} )
    .then(response => {
      //console.log(response.data.message);
      setResp(response.data.message);
      setAuthenticatedUser(user);
      navigate("/search");
    })
    .catch(error => {
      //console.log(error)
      if (error.response.status === 401){
        setResp(error.response.data.error);
      }
      else 
        setResp('Server Error Fetching Response:', error);
    });
  };
  /*turn this to 100% size of browser*/
  return (
    <div className="credentials">
      
      <div className="left-side">
        <img className="logo" src={logo} alt=""></img>
        <h2>Employee Login</h2>
      </div>
      
      <div className="credentials-input">
        <h1>Enter Username and Password</h1>
        <p> {resp} </p>
        <input type="text" id = "user" value={user} onChange={(e) => setUser(e.target.value)} placeholder="username" />
        <input type="password" id = "pass" value={pass} onChange={(e) => setPass(e.target.value)} placeholder="password" />
        <button onClick={checkCreds}>Login</button>
        {twoFAEnabled && 
        <input type="text" id = "otp" value={otp} onChange={(e) => setOTP(e.target.value)} placeholder="123456" /> }
        {twoFAEnabled && 
        <button onClick={checkOTP}>Submit OTP</button>}
      </div>

    </div>
  );
}

export default Authenticate;