import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import './search.css';

const Search = ( {authenticatedUser} ) => {
    const navigate = useNavigate();

    const getInitialAreaState = () => {
        const value = "ALL AREAS";
        return value;
    };
    const getInitialSortByState = () => {
        const value = "Refund";
        return value;
    };
    const getInitialSortDirState = () => {
        const value = "DESC";
        return value;
    };

    const [refunds, setRefunds] = useState([]);
    const [areas, setAreas] = useState([]);
    const [columnNames, setColumnNames] = useState(["Total Refund", "LowPro", "A-Z"]);
    const [sortOpt, setSortOpt] = useState(["ASC", "DESC"]);
    const [input, setInput] = useState('');
    const [value, setValue] = useState(getInitialAreaState);
    const [sortBy, setSortBy] = useState(getInitialSortByState);
    const [sortDir, setSortDir] = useState(getInitialSortDirState);


    if(authenticatedUser === null || authenticatedUser === ""){
        navigate("/login");
    }

    useEffect(() => {
        clearSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const getTaxpayer = () => {
        axios.post('https://tcotinc.com:3005/searchRefundsAndArea/', {data: {area: value, tpName: input, sortBy: sortBy, sortDir: sortDir}}, {withCredentials:true})
        .then(response => {
            setRefunds(response.data[0]);
        })
        .catch(error => console.error('Error fetching refunds:', error));
    };

    const clearSearch = () => {
        setInput('');
        setValue(getInitialAreaState);
        setSortBy(getInitialSortByState);
        axios.get('https://tcotinc.com:3005/getRefunds', {withCredentials:true})
        .then(response => {
            setRefunds(response.data[0]);
            setAreas(response.data[1]);
        })
        .catch(error => console.error('Error fetching refunds:', error));
    };

    const clickMe = (data) => {
        navigate("/taxpayer", { state: {data: data} });
    }

    const handleAreaDropDownChange = (e) => {
        setValue(e.target.value);
    };

    const handleSortByDropDownChange = (e) => {
        setSortBy(e.target.value);
    };

    const handleSortDirDropDownChange = (e) => {
        setSortDir(e.target.value);
    }
    
    return (
        <div className="search-app">
                
            <h1>Marketing Report</h1>
            <p>Disclaimer: All information contained herein is proprietary to T-COT and is provided solely for the purpose of obtaining a contract with T-COT. Unauthorized use, disclosure, or distribution of this information is strictly prohibited. Any other use of this information without the express written consent of T-COT is not permitted.</p>


            <div className="taxpayer-input">
                <input type="text" id = "inputvalue" value={input} onChange={(e) => setInput(e.target.value)} placeholder={"Taxpayer Name"} />
                
                <a>Filter By: </a>

                <select value={value} onChange={handleAreaDropDownChange}>
                    <option value="ALL AREAS">ALL AREAS</option>
                    {areas.map((areaObj, i) => (
                        <option key={i} value={areaObj.Area}>{areaObj.Area}</option>
                    ))}
                </select>
                
                <a>Sort By: </a>

                <select value={sortBy} onChange={handleSortByDropDownChange}>
                    {columnNames.map((columnName, i) => (
                        <option key={i} value={columnName}>{columnName}</option>
                    ))}
                </select>

                <select value={sortDir} onChange={handleSortDirDropDownChange}>
                    {sortOpt.map((sortOption, i) => (
                        <option key={i} value={sortOption}>{sortOption}</option>
                    ))}
                </select>

                <button onClick={getTaxpayer}>Search</button>
                
                <button onClick={clearSearch}>Clear Search</button>

            </div>

            <table className="totalRefund-table">
                <tbody>
                    <tr>
                        <th width="716.48px">TaxpayerName</th>
                        <th width="260.17px">Area</th>
                        <th width="122.34px">LowPro Refund</th>
                        <th width="122.34px">Total Refund</th>
                    </tr>
                    
                    {refunds.map((refundForTP, i) => (
                        <tr height="58px" key={refundForTP.TaxpayerNumber}>
                            <td>
                                <button onClick={() => clickMe({TPNumber: refundForTP.TaxpayerNumber})} className="ViewDetailsBtnLink">{refundForTP.TaxpayerName}</button>
                            </td>
                            <td>
                                {refundForTP.Area}
                            </td>
                            <td>
                                {refundForTP.LowPro}
                            </td>
                            <td>
                                {refundForTP.Refund}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

        </div>
    );
};
 
export default Search;